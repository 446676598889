<template>
  <div class="container">
    <div class="row align-items-center justify-content-center content-body">
      <div
          class="col-12 form-group align-items-center justify-content-center card-step">
        <div class="card-body">
          <div class="title-stepper text-center mb-5">Check your Rank</div>
        </div>
        <div class="row align-items-center justify-content-center mb-5">
          <div class="col-12 col-sm-9 form-group input-group-lg">
            <input type="text" class="form-control" placeholder="Email" v-model="form.email"/>
            <error-message class="mt-1" :msg="errorMessage.email"></error-message>
          </div>
        </div>
        <div class="row align-items-center justify-content-center mb-5">
          <div
              class="col-12 col-sm-9 d-grid gap-2 col-6 mx-auto form-group text-center input-group-lg mb-3">
            <div class="btn btn-start btn-lg w-100" @click="checkRanking">Check</div>
          </div>
          <div class="row mb-5">
            <div class="col-12 col-sm-9 d-grid gap-2 col-6 mx-auto form-group input-group-lg mb-3">
              <div class="text-stepper">
                why wait?
                <router-link to="/register" class="card-primary-second text-decoration-none">Join now!
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import validate from 'validate.js';
import ErrorMessage from "../components/ErrorMessage";
import store from '../store';

export default {
  name: 'WhiteList',
  components: {ErrorMessage},
  data() {
    return {
      form: {
        email: ""
      },
      errorMessage: {}
    }
  },
  methods: {
    async checkRanking() {
      let errorMessage = this.validateForm(this.form);

      if (errorMessage) {
        return this.errorMessage = errorMessage;
      } else {
        this.errorMessage = {};
      }

      if (this.isLoading) return;
      this.isLoading = true;

      this.isLoading = false;

      await this.$router.push('/ranking')
    },
    validateForm(data) {
      let errMessageList = null;

      let validateRule = {
        email: {
          presence: {
            allowEmpty: false,
            message: "^Please enter email"
          },
          email: {
            message: "^Invalid email"
          },
        }
      };

      errMessageList = validate(
          {
            name: data.name,
            email: data.email,
            mobile: data.mobile,
          }, validateRule);

      if (errMessageList) {
        const error = {};
        for (const [key, value] of Object.entries(errMessageList)) {
          error[key] = value[0];
        }
        return error;
      } else {
        return false;
      }

    },
  }
}

</script>


<style scoped>


.btn-start{
  border-radius: 20px;
}
</style>
